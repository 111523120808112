'use client'

import CCP_ImageFallback from '@/components/main/element/ImageFallback'
import { Link } from '@/navigation'
import { useTranslations } from 'next-intl'
import { useEffect } from 'react'

export default function Error({ error, reset }) {
  const __t = useTranslations()
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error)
  }, [error])
  return (
    <div className="tw-px-4 tw-h-[80vh] tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center">
      <CCP_ImageFallback
        src="https://static.bangkokhospital.com/uploads/2024/05/error.svg"
        width={128}
        height={128}
        alt="Error"
      />
      <h3 className="tw-text-center tw-max-w-3xl">
        {__t('sorry-some-features-might-not-work-properly')}
      </h3>
      <div className="tw-mt-12 tw-flex tw-items-center tw-space-x-4">
        <Link prefetch={false} href="/">
          {__t('go-to-homepage')}
        </Link>
        <span className="tw-my-1 tw-text-bgh-gray">•</span>
        <Link prefetch={false} href="/appointment">
          {__t('appointment')}
        </Link>
        <span className="tw-my-1 tw-text-bgh-gray">•</span>
        <Link prefetch={false} href="/contact">
          {__t('contact')}
        </Link>
      </div>
    </div>
  )
}
